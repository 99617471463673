export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6')
];

export const server_loads = [0];

export const dictionary = {
		"/[[lang=langCode]]/analytics": [3],
		"/[[lang=langCode]]/staking": [4],
		"/[[lang=langCode]]/vaults": [5],
		"/[[lang=langCode]]/vaults/[slug]": [6],
		"/[[lang=langCode]]": [2]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';